body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
  a {
    color:white;
  }
  
  html {
    height: -webkit-fill-available;
  }
@mixin for-mobile-only {
    @media screen and (max-width: 640px) {
        @content
    }
}
@mixin for-tablet-only {
    @media screen and (max-width: 1220px) {
        @content
    }
}
@mixin for-laptop-only {
    @media screen and (min-width: 1800px) {
        @content
    }
} 
.signupForm {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 10px;
    
    
    
}
.tempHidden {
  display: none;
}
.bottom-sheet {

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    transition: transform 0.3s ease-out;
    transform: translateY(100%);
    z-index: 200;
    border: 1px solid black;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
  }
  
  .bottom-sheet.open {
    transform: translateY(0%);
  }
  
  .bottom-sheet-content {
    padding: 16px;
    //background-color: rgba(35, 237, 163, 0.913);
    background-color: white;
    //border: 1px solid black;
    
    button {
                
        font-size: 14px;
        border-radius: 15px;
        background-color: rgb(67, 81, 234);
        color:white;
    }
    img {
        width: 40px;
        height: 40px;
    }
    .social-share-buttons {

        display: flex;
        justify-content: space-evenly;
       // gap: 5px;
        //gap: 20px;
        img {
            height: 32px;
            width: 32px;
        }
    }
  }


  .scrollable-content {
    padding: 20px;
  }
.mainContainer {
    background-color: white;
    //border: 3px solid red;
    padding: 20px;
    display: flex;
    align-items: center;
    //height: calc(100vh - 60px);
    .header, .footer {
        background-color: rgba(35, 237, 163, 0.913);
        //height: 60px;
        //border-bottom: 1px solid gray;
    }
    .guest-content {
      display: flex;
      flex-direction: column;
      background-color: white;
      padding-top: 60px;
      width: 50%;
      @include for-mobile-only {
        width: 100%;
      }
      button {
        color: black;
        background-color: rgba(35, 237, 163, 0.913);
        padding: 5px;
        border-radius: 30px;
        font-size: 16px;
        height: 40px;
        cursor: pointer;
        border: 2px white solid;
        width: 100%;
        
       }
        .screenshot {
          
          display: flex;
          flex-direction: column;
          //padding-top: 20px;
          //border: 2px solid green;
          width: 80%;
          @include for-mobile-only {
            width: 100%;
          }
          img {
            width: 50%;
            @include for-mobile-only {
              width: 100%;
            }
          }
          .screenshot-1 {
            img {
              width: 100%;
            }
          }
          .screenshot-left {
            img {
              width: 100%;
            }
          }
          .screenshot-left {
            img {
              width: 100%;
            }
          }
          .screenshot-right {
            img {
              width: 100%;
            }
          }
          
        }
    }
    /* Set the position and styling for the header */
    .header {
        //border: 1px solid black;
        background-color: rgba(35, 237, 163, 0.913);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        min-height: 60px;
        justify-content:space-between;
        
        align-items: center;
        .logo {
            display: flex;
            align-items: center;
            gap:5px;
            //border: 1px solid green;
            font-weight: 500;
        }
       
        .username {
            //border: 1px solid green;
            display: none;
            //justify-content: right;
            margin-left: auto;
            //border: 1px solid red;
            padding: 5px;
            font-weight: 500;
        }
        .header-toolbar {
            //border: 1px solid green;
            display: flex;
            align-items: center;
            gap: 20px;
            justify-content: flex-end;
            img {
                width: 20px;
                height: 20px;
                border-radius: 10px;
                cursor: pointer;
                //border: 1px solid green;
                //padding: 2px;

            }
            //border: 1px solid beige;
            
            button {
                
                font-size: 14px;
                border-radius: 15px;
                background-color: rgb(67, 81, 234);
                color:white;
            }
            
        }
        z-index: 100;
        img {
            height: 40px;
            width: 40px;
            //border-radius: 50%;
        }
      
            font-weight: normal;
            
            padding: 10px;
      }
    
      /* Set the position and styling for the footer */
    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        
        z-index: 100;
        .inputMessage{
                
            display: flex;
            //background-color: green;
            //border: 1px solid black;
            //height: 40px;
            padding: 10px;
            
            .formContainer {
                width: 100%;
                //background-color: rgb(128, 0, 96);
                display: flex;
                gap: 10px;
                
                input {
                    width: 100%;
                    border: 1px solid darkblue;
                    border-radius: 5px;
                    padding: 10px;
                    font-size: 16px;
                }
                button {
                    width: 150px;
                    font-size: 16px;
                    border-radius: 20px;
                    background-color: rgb(67, 81, 234);
                    color:white;
                }
            }
        }
        .loader-visible {
            display: block;
            margin-left: 30px;
            align-items: center;
            justify-content: center;
            width: 10px;
            height: 16px;
            border-radius: 50%;
            background-color: white;
            box-shadow: 32px 0 #ffffff, -32px 0 #fff;
            position: relative;
            animation: flash 0.5s ease-out infinite alternate;
          }
          .loader-hidden {
            display: none;
          }
      }
      /* Set the height, position, and styling for the scrollable area */
    .scrollable-area {
       
        display: flex;
        background-color: white;
        //border: 2px solid green;
        padding-top: 60px;
        //margin-top:60px;
        max-height: calc(100vh - 150px);
        
        //height: 100vh;
        //min-height: calc(100vh - 120px);
        overflow: scroll;
        
        
       // padding-bottom: 60px;
       
       
        .messages {
            //background-color: red;
            color:white;
            display: flex;
            flex-direction: column; 
            //background-color: blue;
            //border: 5px solid blue;
            //padding: 20px;
            padding-bottom: 30px;
            //max-width: 70%;
            
            //height: 570px;
            //height: 80%;
            overflow: scroll;
            gap:10px;
            
            .message {
                margin-right: 100px;
                //border: 10px solid blue;
                display: flex;
                position: relative;
                gap: 10px;
                background-color: rgb(56, 53, 53);
                border-radius: 10px 10px 10px 0px;
                flex-direction: column;
                padding: 10px;
                .invisibleBadge {
                    display:none;
                }
                .badge1 {
                    position: absolute;
                    top: 0px;
                    left: 10px;
                }
                .badge2 {
                    position: absolute;
                    top: 0px;
                    left: 40px;
                }
                
                
                white-space: break-spaces;
                .content {
                    //border: 1px solid green;
                    overflow-x: clip;
                    display: flex;
                    //align-items: center;
                    flex-direction: column;
                    padding: 10px;
                    gap:5px;
                    
                    .suggestions {
                        /*display: flex;*/
                        display: none;
                        flex-direction: column;
                        align-items: center;
                        gap: 10px;
                        
                        button {
                            color: black;
                            background-color: rgba(35, 237, 163, 0.913);
                            padding: 5px;
                            border-radius: 30px;
                            font-size: 16px;
                            height: 40px;
                            cursor: pointer;
                            border: 2px white solid;
                            width: 100%;
                            
                            
                            
                        }
                    }
                    button {
                        color: black;
                        background-color: white;
                        
                        border-radius: 30px;
                        font-size: 16px;
                        //height: 40px;
                        cursor: pointer;
                    }
                    img {
                        display: flex;
                        height: 50px;
                        width: 50px;
                        /*border-radius: 50%;*/
                        
                        
                    }
                    
                }
                .emojis {
                    display: flex;
                    font-size: 25px;
                    //border-top: 1px solid red;
                    align-items:center;
                    //border: 1px solid white;
                    justify-content: space-evenly;
                    gap: 5px;
                    cursor: pointer;
                   
                    .em1 {
                        .animated1 {
                            animation: bounce 0.5s ease-out;
                            
                          }
                          @keyframes rotate {
                            0% {
                              transform: rotate(0deg);
                            }
                            25% {
                              transform: rotate(90deg);
                            }
                            50% {
                              transform: rotate(180deg);
                            }
                            75% {
                              transform: translateY(270deg);
                            }
                            100% {
                              transform: translateY(360deg);
                            }
                          }
                          @keyframes bounce {
                            0% {
                              transform: translateY(0);
                            }
                            25% {
                              transform: translateY(-20px);
                            }
                            50% {
                              transform: translateY(0);
                            }
                            75% {
                              transform: translateY(-10px);
                            }
                            100% {
                              transform: translateY(0);
                            }
                          }
                        gap:5px;
                        display: flex;
                        button {
                            //border-radius: 0px;
                           
                            //background-color: rgb(56, 53, 53);
                            background-color: rgb(67, 81, 234);
                            color:white;
                            border: 2px solid white;
                            height: 40px;

                            width: 60px;
                           
                            font-size: 16px;
                            //height: 40px;
                            cursor: pointer;
                        }
                    }
                    .em2 {
                        .animated2 {
                            animation: bounce 0.5s ease-out;
                          }
                          
                          @keyframes bounce {
                            0% {
                              transform: translateY(0);
                            }
                            25% {
                              transform: translateY(-20px);
                            }
                            50% {
                              transform: translateY(0);
                            }
                            75% {
                              transform: translateY(-10px);
                            }
                            100% {
                              transform: translateY(0);
                            }
                          }
                        gap:5px;
                        display: flex;
                        button {
                            
                            
                           //width: 70px;
                            background-color: rgb(56, 53, 53);
                            //background-color: rgb(67, 81, 234);
                            color:white;
                            border: 1px solid rgba(35, 237, 163, 0.913);;
                            //width: 50px;
                            background-color: rgb(67, 81, 234);
                            color:white;
                            border: 2px solid white;
                            //font-size: 20px;
                            //height: 40px;
                            cursor: pointer;
                        }
                    }
                    .em3 {
                        .animated3 {
                            animation: bounce 0.5s ease-out;
                          }
                          
                          @keyframes bounce {
                            0% {
                              transform: translateY(0);
                            }
                            25% {
                              transform: translateY(-20px);
                            }
                            50% {
                              transform: translateY(0);
                            }
                            75% {
                              transform: translateY(-10px);
                            }
                            100% {
                              transform: translateY(0);
                            }
                          }
                        gap:5px;
                        display: flex;
                        button {
                            
                            
                           //width: 70px;
                            background-color: rgb(56, 53, 53);
                            //background-color: rgb(67, 81, 234);
                            color:white;
                            border: 1px solid rgba(35, 237, 163, 0.913);;
                            //width: 50px;
                            background-color: rgb(67, 81, 234);
                            color:white;
                            border: 2px solid white;
                            //font-size: 20px;
                            //height: 40px;
                            cursor: pointer;
                        }
                    }
                    .em4 {
                        .animated3 {
                            animation: bounce 0.5s ease-out;
                          }
                          
                          @keyframes bounce {
                            0% {
                              transform: translateY(0);
                            }
                            25% {
                              transform: translateY(-20px);
                            }
                            50% {
                              transform: translateY(0);
                            }
                            75% {
                              transform: translateY(-10px);
                            }
                            100% {
                              transform: translateY(0);
                            }
                          }
                        gap:5px;
                        display: flex;
                        button {
                            
                            
                           //width: 70px;
                            //background-color: rgb(56, 53, 53);
                            //background-color: rgb(67, 81, 234);
                            color:white;
                            border: 1px solid rgba(35, 237, 163, 0.913);;
                            //width: 50px;
                            background-color: rgb(67, 81, 234);
                            color:white;
                            border: 2px solid white;
                            //font-size: 20px;
                            //height: 40px;
                            cursor: pointer;
                        }
                    }
                }
                
                
                @include for-mobile-only {
                    margin-right: 5px;
                }
                
           }
           .loggedInUser {
                margin-left: 100px;
                
                //border: 5px solid red;
                
                background-color: rgb(67, 81, 234);
                
                border-radius: 10px 10px 0px 10px;
                padding: 10px;
                @include for-mobile-only {
                    margin-left: 5px;
                }
                
            }
    
            .other {
                justify-content: right;
            }
            .recommendationPanel {
                
                display: flex;
                gap: 10px;
                
                button {
                    color: black;
                    
                    border: 2px solid green;
                    border-radius: 30px;
                    font-size: 16px;
                    height: 40px;
                    cursor: pointer;
                }
                @include for-mobile-only {
                    flex-direction: column;
                }
                @include for-tablet-only {
                    flex-direction: column;
                }
                   
            }
            .recommendationPanel-hidden {
                
                display: none;
                
                   
            }
    
            
        }
      }
    
    display: flex;
    font-size: 16px;
    
    //height: 500px;
    //border: 3px solid red;
    flex-direction: column;
    width: 100%;
    
   
    .chatContainer {
        //background-color: gray;
        //height: 70%;
        max-height: calc(100vh - 120px);
        overflow-y: auto;
        padding-top: 60px;
        padding-bottom: 60px;
       
        
        
          
          @keyframes flash {
            0% {
              background-color: rgba(255, 0, 0, 0.133);
              box-shadow: 32px 0 rgba(169, 5, 5, 0.133), -32px 0 #FFF;
            }
            50% {
              background-color: #455fdf;
              box-shadow: 32px 0 rgba(167, 17, 17, 0.133), -32px 0 rgba(161, 5, 5, 0.133);
            }
            100% {
              background-color: rgba(142, 14, 14, 0.133);
              box-shadow: 32px 0 #2c0ad6, -32px 0 rgba(125, 15, 15, 0.133);
            }
          }
                
    }
   
   
    
     

    
    
}